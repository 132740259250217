var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"盘存","width":780,"visible":_vm.visible,"confirmLoading":_vm.loading,"maskClosable":false},on:{"ok":() => { _vm.$emit('ok', _vm.info) },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"库位"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'cargo_space_id',
                {
                  rules: [
                    { required: true, message: '' },
                  ],
                },
              ]),expression:"[\n                'cargo_space_id',\n                {\n                  rules: [\n                    { required: true, message: '' },\n                  ],\n                },\n              ]"}],attrs:{"option-filter-prop":"children","filter-option":_vm.filterOption,"placeholder":"库位名称"},on:{"search":_vm.handleWmsWarehouseSearch,"change":_vm.handleWmsCargoSpaceChange}},_vm._l((_vm.wmsCargoSpaceList),function(d){return _c('a-select-option',{key:d.id,attrs:{"value":d.id}},[_vm._v(" "+_vm._s(d.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"货品条形码"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'goods_purchase_id',
                {
                  rules: [
                    { required: true, message: '' },
                  ],
                },
              ]),expression:"[\n                'goods_purchase_id',\n                {\n                  rules: [\n                    { required: true, message: '' },\n                  ],\n                },\n              ]"}],attrs:{"option-filter-prop":"children","filter-option":_vm.filterOption,"placeholder":"货品条形码"},on:{"change":_vm.handleWmsGoodsPurchaseChange}},_vm._l((_vm.wmsGoodsPurchaseList),function(d){return _c('a-select-option',{key:d.goods_purchase_id,attrs:{"value":d.goods_purchase_id}},[_vm._v(" "+_vm._s(d.goods_purchase_barcode)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"实际数量"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['current_count', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['current_count', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]"}],attrs:{"min":0}})],1),_c('a-form-item',{attrs:{"label":"差异数量"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['diff_count', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['diff_count', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]"}]})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }