<template>
  <a-modal
    title="盘存"
    :width="880"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="() => { $emit('ok', dataResult) }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-button type="primary" @click="handleAdd" style="margin-bottom: 20px">添加</a-button>
    <a-table :columns="columns" :data-source="dataResult">
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleDelete(record)">删除</a>
        </template>
      </span>
    </a-table>
    <cargo-space-take-inventory-result-form
      ref="takeInventoryResult"
      :visible.sync="visible_from"
      v-if="visible_from"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import CargoSpaceTakeInventoryResultForm from './CargoSpaceTakeInventoryResultForm'

// 表单字段
const fields = ['batch', 'from_warehouse_id', 'count', 'to_warehouse_id']
export default {
  components: {
    CargoSpaceTakeInventoryResultForm
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        }
      },
      visible_from: false,
      mdl: {},
      form: this.$form.createForm(this),
      dataResult: [],
      columns: [
        {
          title: '库位',
          dataIndex: 'cargo_space_name',
          width: '25%'
        },
        {
          title: '货品条形码',
          dataIndex: 'goods_purchase_barcode',
          width: '25%'
        },
        {
          title: '实际数量',
          dataIndex: 'current_count',
          width: '20%'
        },
        {
          title: '差异数量',
          dataIndex: 'diff_count',
          width: '20%'
        },
        {
          title: '操作',
          width: '10%',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  created () {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  mounted () {
    this.mdl = this.model
  },
  methods: {
    handleAddCancel () {
      this.visible_from = false
    },
    handleOk (info) {
      const form = this.$refs.takeInventoryResult.form
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          const obj = this.dataResult.find((item) => {
            return item.cargo_space_id === values.cargo_space_id && item.goods_purchase_id === values.goods_purchase_id
          })
          if (obj === undefined) {
            this.visible_from = false
            this.dataResult.push({
              key: this.dataResult.length + 1,
              ...values,
              ...info
            })
          } else {
            this.$message.warning('已存在')
          }
          console.log('============>>>>>', values, info)
        }
      })
    },
    handleDelete (record) {
      const index = this.data.indexOf(record)
      if (index !== -1) {
        this.dataResult.splice(index, 1)
      }
    },
    handleAdd () {
      this.visible_from = true
    }
  }
}
</script>
