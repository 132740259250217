<template>
  <a-modal
    title="盘存"
    :width="780"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="() => { $emit('ok', info) }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="库位">
              <a-select
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="[
                  'cargo_space_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="库位名称"
                @search="handleWmsWarehouseSearch"
                @change="handleWmsCargoSpaceChange"
              >
                <a-select-option v-for="d in wmsCargoSpaceList" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="货品条形码">
              <a-select
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="[
                  'goods_purchase_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="货品条形码"
                @change="handleWmsGoodsPurchaseChange"
              >
                <a-select-option v-for="d in wmsGoodsPurchaseList" :key="d.goods_purchase_id" :value="d.goods_purchase_id">
                  {{ d.goods_purchase_barcode }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="实际数量">
              <a-input-number :min="0" v-decorator="['current_count', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="差异数量">
              <a-input-number v-decorator="['diff_count', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { cargo_space_list } from '@/api/c_wms_cargo_space'

const { wms_warehouse_inventory_list } = require('@/api/c_wms_period_inventory')

// 表单字段
const fields = ['batch', 'from_warehouse_id', 'count', 'to_warehouse_id']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        }
      },
      info: {
        cargo_space_name: '',
        goods_purchase_barcode: ''
      },
      form: this.$form.createForm(this),
      wmsCargoSpaceList: [],
      wmsGoodsPurchaseList: []
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
    this.handleWmsWarehouseSearch('')
  },
  methods: {
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleWmsWarehouseSearch (value) {
      cargo_space_list({ name: value, warehouse_id: this.model.warehouse_id }).then((res) => {
        const result = res.data.entries || []
        this.wmsCargoSpaceList = result
      })
    },
    handleWmsCargoSpaceChange (value) {
      const objneo = this.wmsCargoSpaceList.find((item) => {
        return item.id === value
      })
      const obj = Object.assign({}, objneo)
      console.log(obj, value)
      this.info.cargo_space_name = obj.name
      this.handleWmsGoodsPurchaseSearch(obj)
    },
    handleWmsGoodsPurchaseSearch (obj) {
      wms_warehouse_inventory_list({ cargo_space_id: obj.id, warehouse_id: obj.warehouse_id }).then(res => {
        const result = res.data.entries || []
        this.wmsGoodsPurchaseList = result
        if (result.length === 0) {
          this.$message.warning('该库位没有货品')
        }
      })
    },
    handleWmsGoodsPurchaseChange (value) {
      const objneo = this.wmsGoodsPurchaseList.find((item) => {
        return item.goods_purchase_id === value
      })
      this.info.goods_purchase_barcode = objneo.goods_purchase_barcode
    }
  }
}
</script>
