<template>
  <a-card>
    <div class="table-operator">
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
        title="库位盘存列表"
        @back="handleGoBack"
      ></a-page-header>
    </div>
    <div class="table-operator" v-if="false">
      <a-button type="primary" icon="plus" @click="handleAdd">盘存</a-button>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a-popconfirm
            v-if="Number(mdl.status) === 1"
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <cargo-space-take-inventory-form
      ref="takeInventoryResult"
      :visible.sync="visible"
      v-if="visible"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CargoSpaceTakeInventoryForm from '../modules/CargoSpaceTakeInventoryForm'

import {
  wms_take_inventory_result_list,
  wms_take_inventory_result_create,
  wms_warehouse_take_inventory_result_delete
} from '@/api/c_wms_period_inventory'

export default {
  name: 'Read',
  components: {
    STable,
    CargoSpaceTakeInventoryForm
  },
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      take_inventory_id: 0,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      mdl: {
        warehouse_id: 0,
        status: 0
      },
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '库位',
          dataIndex: 'cargo_space',
          ellipsis: true
        },
        {
          title: '货品名称',
          dataIndex: 'goods_name',
          ellipsis: true
        },
        {
          title: '货品条形码',
          dataIndex: 'goods_purchase',
          ellipsis: true
        },
        {
          title: '实际数量',
          dataIndex: 'current_count',
          ellipsis: true
        },
        {
          title: '差异数量',
          dataIndex: 'diff_count',
          ellipsis: true
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '100px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return wms_take_inventory_result_list(Object.assign(parameter, this.queryParam, { take_inventory_id: this.take_inventory_id }))
          .then(res => {
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.take_inventory_id = this.$route.params.take_inventory_id
    this.mdl.warehouse_id = this.$route.query.warehouse_id
    this.mdl.status = this.$route.query.status
  },
  methods: {
    handleGoBack () {
      this.$router.go(-1)
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleAdd () {
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
    },
    handleOk (list) {
      this.visible = false
      const form = this.$refs.takeInventoryResult.form
      form.validateFields((errors, values) => {
        if (!errors) {
          const data = {
            take_inventory_id: this.take_inventory_id,
            take_inventory_results: list
          }
          wms_take_inventory_result_create(data).then(res => {
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          })
        }
      })
    },
    handleDelete (record) {
      wms_warehouse_take_inventory_result_delete(record.id).then(res => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    }
  }
}
</script>
