import request from '@/utils/request'
const requestApi = {
  wms_cargo_space_list: '/wms/warehouse/cargo_space/',
  wms_cargo_space_create: '/wms/warehouse/cargo_space/',
  wms_cargo_space_update: '/wms/warehouse/cargo_space/',
  wms_cargo_space_delete: '/wms/warehouse/cargo_space/'
}

export function cargo_space_list (query) {
  return request({
    url: requestApi.wms_cargo_space_list,
    method: 'get',
    params: query
  })
}

export function cargo_space_create (data) {
  return request({
    url: requestApi.wms_cargo_space_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function cargo_space_update (data, cargo_space_id) {
  return request({
    url: requestApi.wms_cargo_space_update + cargo_space_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function cargo_space_delete (cargo_space_id) {
  return request({
    url: requestApi.wms_cargo_space_update + cargo_space_id + '/',
    method: 'delete'
  })
}
