import request from '@/utils/request'
const requestApi = {
  goods_inventory_create: '/wms/warehouse/goods_inventory/',
  wms_warehouse_inventory_list: '/wms/warehouse/goods_inventory/',
  wms_warehouse_take_inventory_list: '/wms/warehouse/take_inventory/',
  wms_warehouse_take_inventory_create: '/wms/warehouse/take_inventory/',
  wms_warehouse_take_inventory_update: '/wms/warehouse/take_inventory/',
  wms_warehouse_take_inventory_delete: '/wms/warehouse/take_inventory/',
  wms_warehouse_take_inventory_result_create: 'wms/warehouse/take_inventory_result/',
  wms_warehouse_take_inventory_result_list: 'wms/warehouse/take_inventory_result/',
  wms_warehouse_take_inventory_result_delete: 'wms/warehouse/take_inventory_result/',
  wms_warehouse_take_inventory_confirm_diff_list: '/wms/warehouse/take_inventory_confirm_diff/',
  wms_warehouse_take_inventory_confirm_diff_create: '/wms/warehouse/take_inventory_confirm_diff/',
  wms_warehouse_take_inventory_check_uninventory_diff: '/wms/warehouse/take_inventory_check_uninventory_diff/',
  wms_warehouse_take_inventory_check_uninventory_diff_create: '/wms/warehouse/take_inventory_check_uninventory_diff/',
  wms_warehouse_take_inventory_excel_list: '/wms/warehouse/take_inventory_excel/',
  wms_warehouse_take_inventory_excel_create: '/wms/warehouse/take_inventory_excel/'
}

export function goods_inventory_create (data) {
  return request({
    url: requestApi.goods_inventory_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function wms_take_inventory_result_create (data) {
  return request({
    url: requestApi.wms_warehouse_take_inventory_result_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function wms_warehouse_inventory_list (query) {
  return request({
    url: requestApi.wms_warehouse_inventory_list,
    method: 'get',
    params: query
  })
}

export function wms_take_inventory_result_list (query) {
  return request({
    url: requestApi.wms_warehouse_take_inventory_result_list,
    method: 'get',
    params: query
  })
}
export function wms_warehouse_take_inventory_list (query) {
  return request({
    url: requestApi.wms_warehouse_take_inventory_list,
    method: 'get',
    params: query
  })
}
export function wms_warehouse_take_inventory_confirm_diff_list (query) {
  return request({
    url: requestApi.wms_warehouse_take_inventory_confirm_diff_list,
    method: 'get',
    params: query
  })
}
export function wms_warehouse_take_inventory_check_uninventory_diff (query) {
  return request({
    url: requestApi.wms_warehouse_take_inventory_check_uninventory_diff,
    method: 'get',
    params: query
  })
}
export function wms_warehouse_take_inventory_check_uninventory_diff_create (data) {
  return request({
    url: requestApi.wms_warehouse_take_inventory_check_uninventory_diff_create,
    method: 'post',
    data: data
  })
}
export function wms_warehouse_take_inventory_excel_list (query) {
  return request({
    url: requestApi.wms_warehouse_take_inventory_excel_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    responseType: 'blob',
    params: query
  })
}

export function wms_warehouse_take_inventory_create (data) {
  return request({
    url: requestApi.wms_warehouse_take_inventory_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function wms_warehouse_take_inventory_delete (take_inventory_id) {
  return request({
    url: requestApi.wms_warehouse_take_inventory_delete + take_inventory_id + '/',
    method: 'delete'
  })
}

export function wms_warehouse_take_inventory_result_delete (take_inventory_result_id) {
  return request({
    url: requestApi.wms_warehouse_take_inventory_result_delete + take_inventory_result_id + '/',
    method: 'delete'
  })
}

export function wms_warehouse_take_inventory_update (data, take_inventory_id) {
  return request({
    url: requestApi.wms_warehouse_take_inventory_update + take_inventory_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function wms_warehouse_take_inventory_confirm_diff_create (data) {
  return request({
    url: requestApi.wms_warehouse_take_inventory_confirm_diff_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function wms_warehouse_take_inventory_excel_create (data) {
  return request({
    url: requestApi.wms_warehouse_take_inventory_excel_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
